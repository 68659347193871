import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import notification from '@/assets/notification/successnotification.mp3'

export default {
  methods: {
    showSuccessMessage(text) {
      const audio = new Audio(notification)
      audio.play()
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
          text,
        },
      })
    },
  },
}
